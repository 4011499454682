import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import BrandCTA from '../../../components/brand-cta'
import { withPrefix } from "gatsby"
import ExternalLink from '../../../components/external-link'
import ActiveLink from '../../../components/active-link'
import Hero from '../../../components/hero'
import chart1 from '../../../images/Continuous_LH_Chart.png'
import chart2 from '../../../images/Sustained_LH_Chart.png'
import polaroid from '../../../images/PP_Care_polaroid.png'

const metaTags = {
  description: 'Post-procedure care tips you can provide to parents and caregivers.',
  keywords: 'Homepage',
  title: 'Post-procedure Care | SUPPRELIN® LA (histrelin acetate)'
}

const externalLinksMetaTags = {
  category: 'External Link',
  action: 'Click',
  label: 'Deerfield Homepage',
}

const IndexPage = () => (
  <Layout meta={metaTags} hasReferences={true}>
    <Row>
      <Col xs={12}>
        <h1>Post-procedure care</h1>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
        <h2>Children should have regular visits with a pediatric endocrinologist while undergoing treatment with SUPPRELIN<sup>&reg;</sup> LA.<sup>1</sup></h2>
        <p>Monitor patients for signs of puberty</p>
        <ul className='brand-list'>
          <li>Blood tests (1 month post-implantation, and every 6 months thereafter)</li>
          <li>Height and bone age should be assessed every 6-12 months</li>
        </ul>
        <h2> Please share the following post-procedure care points with caregivers<sup>1</sup>:</h2>
        <ul className='brand-list'>
          <li>Patients should not remove the bandage for at least 24 hours to help ensure the incision heals properly </li>
          <li>While bandaged, the child&rsquo;s arm should be kept clean and dry, and he or she should avoid swimming or bathing for 24 hours</li>
          <li>Do not remove surgical strips; they will fall off on their own after several days </li>
          <li>Caregivers should monitor the incision site until it has fully healed and report any severe pain, redness, or swelling in and around the implant site. Infrequently, SUPPRELIN<sup>&reg;</sup> LA may be expelled from the body through the original incision site, rarely without the patient noticing </li>
          <li>Patients should refrain from strenuous physical activity with the affected arm for 7 days to allow the incision to fully close </li>
          <li>After the incision has healed, the child can resume normal activities</li>
          <li>Patients should return for routine checks of their condition and to ensure that the implant is present and functioning properly</li>
        </ul>
      </Col>
      <Col xs={12} md={6}>
        <img src={polaroid} alt="Polaroid 2" title="Polaroid 2" />
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          ExternalLink
          href={withPrefix("/pdfs/SP-05689_Supprelin_LA_Caregiver_Brochure_Digital.pdf")}
          LinkCaption="Download the SUPPRELIN<sup>&reg;</sup> LA caregiver brochure: important information for patients - post prodcedure care"
        >
          Download the SUPPRELIN<sup>&reg;</sup> LA caregiver brochure: important information for patients
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/hcp/caregiver-resources"
          LinkCaption="View videos designed to help your patients understand CPP and SUPPRELIN<sup>&reg;</sup> LA - post prodcedure care"
        >
          View videos designed to help your patients understand CPP and SUPPRELIN<sup>&reg;</sup> LA
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
